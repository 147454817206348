
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {DatePipe} from '@angular/common';
import { SharedService } from '../shared/gallery.service';
import {Router} from '@angular/router';
import * as $ from 'jquery';
import { configFile}  from '../shared/config';
  declare var commonFile,  galleryJs;
@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PhotoGalleryComponent implements OnInit {
    
    scrollLock = false;
    configFile=new  configFile();
    
    galleryImg = this.configFile.galleryImg; errYearMsg = ''; errAlbumMsg = ''; errSearchMsg = ''; latestAlbum = [];
    errLatestAlbum = ''; filterApplied = 0; TotalAlbum; noDataFound = 0; page = 1; gallery = [];
    errorMsg = ''; searchBy = '1'; years = []; allSearch = ''; AlbumTitle = ''; year = ''; eventDate = '';
    Descriptions = ''; text = ''; pipe = new DatePipe('en-US'); albumName = ''; eventOn = ''; updatedOn = '';
    totalPhoto = ''; description = ''; latestAlbumId = ''; recordStatus = 'Load More';
    constructor(private _Service: SharedService, private router: Router) { }
    
    ngOnInit(): void {        
        var self = this;  this.loadYear(); this.getLatestAlbum();
     
        $(window).scrollTop(0);
        setTimeout(function () { 
            $(window).scroll(function () {                
                if (!self.scrollLock) {
                    $('.pageLoaders').each(function () {
                        if (commonFile.isScrolledIntoView($(this))) {
                            if (self.recordStatus == 'Load More') {
                                self.scrollLock = true;
                                if (self.filterApplied == 0) { self.loadAlbum(self.latestAlbumId) }
                                else {
                                    self.searchAlbum(self.page);
                                }
                            }
                        }
                    });
                }
                if (self.noDataFound != 0) {
                    self.noRecordFound();
                }
            });
        }, 3000)




        
    }
    loadYear() {
        this._Service.getYears().subscribe(data => {            
            if (data.statusCode == 200 && data.errorMessage == 'OK') {                
                this.years = data.data; this.errYearMsg = '';
            }
        },
            error => {                
                this.errYearMsg = error.message;
            }
        )
    }
    loadAlbum(id) {
        if (this.noDataFound == 0) {
            this._Service.getAlbum(this.page,id).subscribe(
               
                data => {
                    
                    if (data.statusCode == 200 && data.errorMessage == 'OK') {
                        var arr = data.data;
                       
                        if (arr.length > 0 || this.page == 1) {                            
                            $('#loader').css('display', 'none');
                            this.recordStatus = 'Load More'; 
                            for (let i = 0; i < arr.length; i++) {
                              //  this.TotalAlbum=arr[0].totalRecord
                                this.gallery.push(arr[i]);
                            } 
                            galleryJs.trimPhotoHeader();
                            galleryJs.trim();
                      //      this.TotalAlbum = this.gallery.length;
                            this.errAlbumMsg = '';                            
                            
                            this.page++;
                        }
                        else {
                            this.noRecordFound()
                        }
                        setTimeout( ()=> {
                            this.scrollLock = false;
                        }, 1000)
                    }
                    else {
                        this.noRecordFound()
                    }
                },
                error => this.errAlbumMsg = error
            )
        }
    }
    
    searchAlbum(page) {        
        this.recordStatus = 'Load More';
        $('#loader').css('display', 'none');
        
        this.page = page;
        if (this.searchBy == '1') this.text = this.allSearch
        else if (this.searchBy == '2') this.text = this.AlbumTitle
        else if (this.searchBy == '3') this.text = this.year
        else if (this.searchBy == '4') {
            var d;
            if (this.eventDate != null) {
                d = this.pipe.transform(this.eventDate, 'dd-MMM-yyyy');
            }
            else { d = '' }
            this.text = d;
        }
        else if (this.searchBy == '5') this.text = this.Descriptions;
        if (!this.text) {this.noDataFound = 0; this.page = 1; this.filterApplied = 0; this.gallery = []; this.loadAlbum(this.latestAlbumId); return false}
        this.filterApplied++; 
        
        this._Service.searchData(this.searchBy, this.text, this.page).subscribe(
            data => {
                
                if (data.statusCode == 200 && data.errorMessage == 'OK') {
                    var arr = data.data;
                    console.log(arr)
                    if ( arr.length > 0 || this.page == 1) {
                        if (this.page == 1) {this.gallery = [];}
                        for (let i = 0; i < arr.length; i++) {
                            this.gallery.push(arr[i]);
                        } 
                        
                        this.page++;
                        if(arr.length > 0)
                        this.TotalAlbum = arr[0].totalRecord
                        else
                        this.TotalAlbum = this.gallery.length;
                    
                        galleryJs.trim();
                        this.errSearchMsg = '';
                    }
                    else {
                        this.noRecordFound()
                    }
                }
                else {
                    
                    this.noRecordFound()
                }
            },
            error => {                
                this.errSearchMsg = error.message
            }
        )
    }
    noRecordFound() {
        $('#loader').css('display', 'none');
        this.recordStatus = 'No more Records found';
        this.noDataFound++;

    }
    loadMore(txt) {
         
        var self = this;
        if (txt == 'Load More') {
            if (this.filterApplied == 0) { this.loadAlbum(this.latestAlbumId) }
            else {this.searchAlbum(self.page);}            
        }
        else {
            this.noRecordFound()
        }
    }
    getLatestAlbum() {
        this._Service.getLatestAlbum().subscribe(
            data => {                
                var album = data.data[0]
                this.albumName = album.albumName;
                this.eventOn = album.eventOn
                this.updatedOn = album.updatedOn
                this.totalPhoto = album.totalPhoto     
                this.TotalAlbum = album.totalRecord               
                this.description = album.description
                this.latestAlbumId = album.id;
                this._Service.getImages(this.latestAlbumId).subscribe(
                    data => {                        
                        this.latestAlbum = data.data;
                        this.loadAlbum(this.latestAlbumId);
                       
                        setTimeout(() => {
                            galleryJs.initSlider();
                            galleryJs.moveSlider();
                           }, 500);
                    },
                    error => {
                        this.errLatestAlbum = error.errorMessage;
                    }
                )
                
            },
            error => {
                this.errLatestAlbum = error.errorMessage;
            }
        )
    }
}
