
export class configFile
{  
  Email='contact.testweb@gmail.com';
  Password='contact@123';
    servicePath='./../../shared/services/image-gallery.service';
  schoolCode = 'FSPSGN';
  catpchaKey='6LekR58UAAAAAKxhmXeZsTsb5Mo9fAkrMZjL5IZ5'

    //schoolCode: 'sjssgn',

        rootUrl ='http://webapi.franciscanwebsolutions.com/api/School/';
   //rootUrl ='http://localhost:58926/api/School/';
    AWSUrl = 'https://d3f79q5uhzuii8.cloudfront.net/SchImg/' + this.schoolCode+'/'
    Ecare = 'https://d3f79q5uhzuii8.cloudfront.net/SchImg/' + this.schoolCode+''
    achievementsImg = 'https://d3f79q5uhzuii8.cloudfront.net/SchImg/' + this.schoolCode +'/Achievement/';
  galleryImg = 'https://d3f79q5uhzuii8.cloudfront.net/SchImg/' + this.schoolCode +'/PhotoAlbum/';
  mediaIconUrl = 'https://d3f79q5uhzuii8.cloudfront.net/SchImg/' + this.schoolCode +'/Media/';
  videoImgUrl = 'https://d3f79q5uhzuii8.cloudfront.net/SchImg/' + this.schoolCode +'/VideoImg/';
  noticeUrl = 'https://d3f79q5uhzuii8.cloudfront.net/SchImg/' + this.schoolCode +'/Notice/';
    dataNotFound='Data Not Available';
    removejscssfile(filename, filetype) {
        var targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" //determine element type to create nodelist from
        var targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" //determine corresponding attribute to test for
        var allsuspects = document.getElementsByTagName(targetelement)
        for (var i = allsuspects.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
          if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1) {
    
            allsuspects[i].outerHTML = "";
            if(allsuspects[i]){
            allsuspects[i].parentNode.removeChild(allsuspects[i])
            } //remove element by calling parentNode.removeChild()
          }
        }
      }
      addscssfile(filename, filetype) {
          
        var targetelement = (filetype == "js") ? "script" : (filetype == "css") ? "link" : "none" //determine element type to create nodelist from
        var targetattr = (filetype == "js") ? "src" : (filetype == "css") ? "href" : "none" //determine corresponding attribute to test for
       if(filetype=='css'){
        var link =document.createElement("link");
        link.href=filename;
         link.rel = 'stylesheet'
          link.type = "text/css" 
           document.head.appendChild(link)
         }else{
          var script =document.createElement("script");
          script.src=filename;   
          document.head.appendChild(script)
         }
   
      }
   
}
