import { Component, OnInit } from '@angular/core';
declare var galleryJs
@Component({
  selector: 'app-infrastructure',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.css']
})
export class InfrastructureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
   // galleryJs.loadaccordion()
  }

}
