import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-school-anthem',
  templateUrl: './school-anthem.component.html',
  styleUrls: ['./school-anthem.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class SchoolAnthemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
