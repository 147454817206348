import { Component, OnInit, ViewEncapsulation, Renderer2 } from '@angular/core';
import { SharedService } from '../shared/gallery.service';
import { Router } from '@angular/router';
import { configFile } from '../shared/config';
declare var  galleryJs;
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class ActivitiesComponent implements OnInit {
  page:number
  TotalAlbum:any
  
  photos=[]
  configFile:any
  galleryImg:any
  gallery=[];
  constructor(public _Service: SharedService, private render: Renderer2,private router: Router) {
    this.configFile=new  configFile();
    this.galleryImg = this.configFile.galleryImg;
    this.page=1 
    this._Service.getActivityAlbum(this.page).subscribe(
             
      data => {
          
          if (data.statusCode == 200 && data.errorMessage == 'OK') {          
              this.gallery=new Array<gallery>()
               for (var i =0;i< data.data.length-1;i++){
                 var gl=new gallery();
                 gl.Id=data.data[i].id
                 gl.albumName=data.data[i].albumName
                 this.gallery.push(gl);
                } 
                setTimeout(()=>{                  
                  galleryJs.clickaccordinan();              
                },500)

          }
      }
  )
  
  
  }

  ngOnInit():void {

    
  }


  loadphto(albumId,index) {
    
    if(!this.gallery[index].photos){
    this._Service.getImages(albumId).subscribe(
           
      data => {
          
          if (data.statusCode == 200 && data.errorMessage == 'OK') { 
            
              var  photolist=new Array<photos>();
              for (var i =0;i< data.data.length-1;i++){
                var  photobj=new photos();
                photobj.photopath=data.data[i].photopath
                photolist.push(photobj);
              }
              this.gallery[index].photos=photolist;
              
          }
      }
  )
    }
  
  }
}
export class gallery
{
albumName:string;
Id:number;
photos:photos;
}
export class photos{
  photopath:string
  
}