import { Component, OnInit } from '@angular/core';
declare var commonFile, galleryJs;
@Component({
  selector: 'app-virtual-tour',
  templateUrl: './virtual-tour.component.html',
  styleUrls: ['./virtual-tour.component.css']
})
export class VirtualTourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    galleryJs.LoadVertualTour();
  }
   
  
   myFunction1() {
    galleryJs.myFunction1()
  }
  myFunction2() {
    galleryJs.myFunction2()
  
  }
  myFunction3() {
    galleryJs.myFunction3()
  
  }
}
