import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-fee-structure',
  templateUrl: './fee-structure.component.html',
  styleUrls: ['./fee-structure.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class FeeStructureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
