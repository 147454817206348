import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
    
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 
        debugger 
        
        if (state.url.includes('page')) {
            var drtl=state.url
            if(this.router.url.includes("/q/")){
             drtl=drtl.replace('page','q ')
            }else{
                drtl=drtl.replace('page','q')
            }
            this.router.navigate([drtl]);
            return true;
        }

        // not logged in so redirect to login page with the return url and return false
      
        return true;
    }
}