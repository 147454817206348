import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared/gallery.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';
declare var commonFile;
import { configFile } from '../shared/config';
var scrollLock = false;
@Component({
  selector: 'app-achievement-detail',
  templateUrl: './achievement-detail.component.html',
  styleUrls: ['./achievement-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AchievementDetailComponent implements OnInit {
  description; event; eventDate; eventVenue; image; totalRecord;
  configFile = new configFile();
  imgUrl = this.configFile.achievementsImg
  constructor(private route: ActivatedRoute, private service: SharedService, private router: Router) { }
  ngOnInit() {
    this.route.params.subscribe(
      data => {
        var id = data["id"];
        this.achievementDetail(id);
      },
      error => {
      }
    )
  }
  achievementDetail(id) {
    this.service.AchievementDetail(id).subscribe(
      data => {
        var arr = data.data;
        this.description = arr[0].description;
        this.event = arr[0].event;
        this.eventDate = arr[0].eventDate;
        this.eventVenue = arr[0].eventVenue;
        this.image = arr[0].image;
        this.totalRecord = arr[0].totalRecord;
      },
      error => {

      }
    )
  }
}
