
import { Injectable, Output, EventEmitter } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ScriptStore} from "./ScriptLoad.module";
import { configFile } from './config';
import { AlertService } from '../ngx-alerts/src/public_api';



@Injectable()
export class SharedService { 
    private config =new configFile();
   
  constructor(private http: HttpClient,private alerts: AlertService) { 
     
  }
  readonly schoolcode=this.config.schoolCode
  readonly rootUrl = this.config.rootUrl
  getflyer() {
    return this.http.get<Response>(this.rootUrl + 'getFlyer?schoolcode='+this.schoolcode )
}
getLFDData() {
    return this.http.get<Response>(this.rootUrl + 'getLFDData?schoolcode='+this.schoolcode )
}
getToppersData() {
    return this.http.get<Response>(this.rootUrl + 'getToppers?schoolcode='+this.schoolcode )
}
getButtonList() {
    return this.http.get<Response>(this.rootUrl + 'getWebButtons?schoolcode='+this.schoolcode )
}
getWebMenusList() {
    return this.http.get<Response>(this.rootUrl + 'getWebMenus?schoolcode='+this.schoolcode )
}
getWebPageContent(pageName) {
    return this.http.get<Response>(this.rootUrl + 'getPageContent?schoolcode='+this.schoolcode+"&pageName="+pageName )
}
getHomeSlider() {
    return this.http.get<Response>(this.rootUrl + 'getHomeSlider?schoolcode='+this.schoolcode )
}
getAchievements(page) {
    return this.http.get<Response>(this.rootUrl + 'getAchievement?schoolcode=' + this.schoolcode + '&pg=' + page)
}
achievementsYear() {
    return this.http.get<Response>(this.rootUrl + 'getAchievementYears?schoolcode=' + this.schoolcode )
}
AchievementDetail(id) {
    return this.http.get<Response>(this.rootUrl + 'GetSingleAchievementById?schoolcode=' + this.schoolcode + '&ID=' + id)
}
searchAchievements(SearchBy, text, page) {        
    return this.http.get<Response>(this.rootUrl + 'getAchievementSearch?schoolcode=' + this.schoolcode + '&type=' + SearchBy + '&search=' + text + '&pg=' + page)
}
// Achievements Component End --
//Birth Day Component Start --  
getStudentBirthDayToday() {    
    return this.http.get<Response>(this.rootUrl + 'getStudentBirthDayToday?schoolcode=' + this.schoolcode)
}
getTeacherBirthDayToday() {
    
    return this.http.get<Response>(this.rootUrl + 'getTeacherBirthDayToday?schoolcode=' + this.schoolcode)
}
getBirthDayTeacherofMonth() {
    
    return this.http.get<Response>(this.rootUrl + 'getTeacherBirthDayOfMonth?schoolcode=' + this.schoolcode)
}
//Birth Day Component End -- 
//Image Galery Start--
getAlbum(albumPage,id) {        
  return this.http.get<Response>(this.rootUrl + 'getAllAlbum?schoolcode=' + this.schoolcode + '&pg=' + albumPage + '&id=' + id+'&type=')
}
getActivityAlbum(albumPage) {        
    return this.http.get<Response>(this.rootUrl + 'getAllAlbum?schoolcode=' + this.schoolcode + '&pg=' + albumPage + '&id=&type=2')
  }
  getLatestAlbum() {
    return this.http.get<Response>(this.rootUrl + 'getLatestAlbum?schoolcode=' + this.schoolcode )
  }
getYears() {
    return this.http.get<Response>(this.rootUrl + 'getPhotoAlbumYears?schoolcode=' + this.schoolcode)
}
searchData(type, text, page) {
    text = text.split(' ').join('%20');
    return this.http.get<Response>(this.rootUrl + 'getPhotoAlbumSearch?schoolcode=' + this.schoolcode + '&type=' + type + '&search=' + text + '&pg=' + page)
}
//  Image Gallery End ----
//Album Images start ---
getAlbumById(albumId) {
    return this.http.get<Response>(this.rootUrl + 'getSingleAlbumById?schoolcode=' + this.schoolcode + '&Id=' + albumId )
}
getImages(albumId) {
    return this.http.get<Response>(this.rootUrl + 'getPhotoByAlbumId?schoolcode=' + this.schoolcode + '&AlbumId=' + albumId )
}
getImagesByPage(albumId, page) {
    
    return this.http.get<Response>(this.rootUrl + 'getPhotoByAlbumIdPageWise?schoolcode=' + this.schoolcode + '&AlbumId=' + albumId +'&pg='+page)
}
//Album Images End ---
//  Video Gallery Start  --
getVideo(headinglength) {
    return this.http.get<Response>(this.rootUrl + 'getAllVideoOfAlbum?schoolcode=' + this.schoolcode + '&headinglength=' + headinglength)
}
getAllVideoOfAlbum(id) {
    return this.http.get<Response>(this.rootUrl + 'getAllVideoOfAlbum?schoolcode=' + this.schoolcode + '&id=' + id)
}
  getLatestVideoAlbum(headinglength) {
    return this.http.get<Response>(this.rootUrl + 'getLatestVideoAlbum?schoolcode=' + this.schoolcode + '&headinglength=' + headinglength)
  }
getVideoSingleDetail(id) {
    
    return this.http.get<Response>(this.rootUrl + 'getVideoGetSingleDetail?schoolcode=' + this.schoolcode + '&id=' + id)
}

getVideoYears() {
    return this.http.get<Response>(this.rootUrl + 'getVideoAlbumYears?schoolcode=' + this.schoolcode)
}
searchVideoData(type, text, page) {
               
    text = text.split(' ').join('%20');
    return this.http.get<Response>(this.rootUrl + 'getVideoAlbumSearch?schoolcode=' + this.schoolcode + '&type=' + type + '&search=' + text + '&pg='+ page)
}
getVideoAlbum(albumPage,id) {        
    return this.http.get<Response>(this.rootUrl + 'getVideoAlbum?schoolcode=' + this.schoolcode + '&pg=' + albumPage + '&id=' + id+'&type=')
  }
//  Video Gallery End ---
//Media Gallery Start--
getMedia(type, text, page) {        
    return this.http.get<Response>(this.rootUrl + 'getMediaGetAllSearch?schoolcode=' + this.schoolcode + '&type=' + type + '&search=' + text + '&pg=' + page)
}
getMediaYears() {
    return this.http.get<Response>(this.rootUrl + 'GetMediaAlbumAllYears?schoolcode=' + this.schoolcode)
}
getMediaGetSingleDetail(id) {
    return this.http.get<Response>(this.rootUrl + 'getMediaGetSingleDetail?schoolcode=' + this.schoolcode + '&id=' + id);
}
MediaGetAllSearch(type, text, page) {
        
    text = text.split(' ').join('%20');
    return this.http.get<Response>(this.rootUrl + 'getMediaGetAllSearch?schoolcode=' + this.schoolcode + '&type=' + type + '&search=' + text + '&pg=' + page)
}
// Media Gallery End ---
//Event Component Start --

downloadFile() {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get('http://ecare.franciscanecare.com/SchImg/SMCALD/Notice/Notice_413151528662.pdf', { headers: headers, responseType: 'blob' as 'json' });

//.subscribe(
//             (response) => {
//                 debugger
//               var mediaType = 'application/pdf';
//               var blob = new Blob([response._body], {type: mediaType});
//               var filename = 'test.pdf';
//               saveAs(blob, filename);
//             },((res)=>{
//                 debugger
           // })
           // );
// let headers = new HttpHeaders();
// headers = headers.set('Accept', 'application/pdf');   
// this.http.get(
//       'https://d3f79q5uhzuii8.cloudfront.net/SchImg/SMCALD/Notice/Notice_413151528662.pdf', { headers: headers, responseType: 'blob' }).subscribe(
//         (response) => {
//             debugger
//           var mediaType = 'application/pdf';
//           var blob = new Blob([response._body], {type: mediaType});
//           var filename = 'test.pdf';
//           saveAs(blob, filename);
//         });
  }
getEventsAll() {
    return this.http.get<Response>(this.rootUrl + 'getAllEvent?schoolcode=' + this.schoolcode)
}
getEventShowAllMonth() {        
    return this.http.get<Response>(this.rootUrl + 'getEventShowAllMonth?schoolcode=' + this.schoolcode)
}
getEventsOfMonth(month) {        
    return this.http.get<Response>(this.rootUrl + 'getMonthOfEvent?schoolcode=' + this.schoolcode + '&Month=' + month)
}
//Event Component End --
getGallery() {
    var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get<any>(this.rootUrl + 'api/gallery/Photo?SchCode=fspsgn&key=jg98ATQO6XksZUmA5Ni7yw==&pg=1', { headers: reqHeader });
}
getSchoolDetail(schoolcode) {
    var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.get<any>(this.rootUrl + 'api/school/DTL?SchCode=FSPSGN&key=jg98ATQO6XksZUmA5Ni7yw==', { headers: reqHeader });
}

getTopTenActiveNotice() {
    return this.http.get<Response>(this.rootUrl + 'getTopTenActiveNotice?schoolcode=' + this.schoolcode + '');
}
getToPTenAchievement() {
    return this.http.get<Response>(this.rootUrl + 'getToPTenAchievement?schoolcode=' + this.schoolcode + '&headingleangth=' + 30);
}
loadBirthDayStudentToday() {
    
    return this.http.get<Response>(this.rootUrl + 'getStudentBirthDayToday?schoolcode=' + this.schoolcode);
}
loadBirthDayTeacherToday() {
    return this.http.get<Response>(this.rootUrl + 'getTeacherBirthDayToday?schoolcode=' + this.schoolcode);
}
getPhotoAlbumForSlider() {
    return this.http.get<Response>(this.rootUrl + 'getPhotoAlbumForSlider?schoolcode=' + this.schoolcode + '&headinglength=' + 10);
}

getAllActiveNotice() {
    return this.http.get<Response>(this.rootUrl + 'getAllActiveNotice?schoolcode=' + this.schoolcode);
}
GetSingleNoticeById(id) {
    return this.http.get<Response>(this.rootUrl + 'GetSingleNoticeById?schoolcode=' + this.schoolcode + '&id=' + id);
}
getOtherNotice(id) {
    return this.http.get<Response>(this.rootUrl + 'getOtherNotice?schoolcode=' + this.schoolcode + '&id=' + id);
}
getGuestBookComment() {
    return this.http.get<Response>(this.rootUrl + 'getGuestBookComments?schoolcode=' + this.schoolcode);
}
PostJoinUs(model) {
    var reqHeader = new HttpHeaders({ 'No-Auth': 'True' });
    return this.http.post<Response>(this.rootUrl + 'PostJoinUs?schoolcode=' + this.schoolcode, model);        
}
PostWriteUsComment(model) {
   
    return this.http.post<Response>(this.rootUrl + 'PostWriteUs?schoolcode=' + this.schoolcode, model)
}
PostMailUs(model) {
   
    return this.http.post<Response>(this.rootUrl + 'PostMailUs?schoolcode=' + this.schoolcode, model)
}


alert(fn: string, msg) {
    this.alerts[fn](msg);
  };
  @Output() change: EventEmitter<any> = new EventEmitter();
   
  bindMap(lat, lng) {      
      var par = { lat: lat, lng: lng }
      this.change.emit(par);
  }
}
export class RequestModel{

    name:string;
    emailID:string;
    subject:string;
    message:string;
	Address:string;
	Contact:string;
	post:string;
	file:any [];
}

export class Response{
    statusCode:number;
    errorMessage:string;
    data:any;
}


