import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared/gallery.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
declare var commonFile, galleryJs;
import { configFile } from '../shared/config';

@Component({
  selector: 'app-media-detail',
  templateUrl: './media-gallery-detail.component.html',
  styleUrls: ['./media-gallery-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MediaGalleryDetailComponent implements OnInit {
  configFile = new configFile();
  
  mediaIconUrl :any;
  mediaIconUrlThumb :any;
  private config =new configFile();

  constructor(private router: Router, private route: ActivatedRoute, private service: SharedService) {
this.mediaIconUrl= this.configFile.mediaIconUrl+"Original/";
this.mediaIconUrlThumb= this.configFile.mediaIconUrl+"Thumb";
    this.route.params.subscribe(
      data => {
        var id = data["id"];
        this.config.addscssfile('../../assets/stylesheet/gallery.css','css')
        this.getMediaDetail(id)
      },
      error => {
      }
    )
   }

  ngOnInit() {
   
  }

  date; details; heading; id; newsName; imageIcon; total
  getMediaDetail(id) {
    this.service.getMediaGetSingleDetail(id).subscribe(
      data => {
        debugger
        var arr = data.data;
        this.date = arr[0].date;
        this.details = arr[0].details;
        this.heading = arr[0].heading;
        this.id = arr[0].id;
        this.imageIcon = arr[0].imageIcon;
        this.newsName = arr[0].newsName;
        this.total = arr[0].total;
     setTimeout(() => {
      galleryJs.initImages();
       
      galleryJs.viewMediaImage();
     }, 1000);
        
      },
      error => {
      }
    )
  }

}
