import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vsms',
  templateUrl: './vsms.component.html',
  styleUrls: ['./vsms.component.css']
})
export class VsmsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
