import { Component, OnInit } from '@angular/core';
declare var  galleryJs;
@Component({
  selector: 'app-holiday-list',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['./holiday-list.component.css']
})
export class HolidayListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    setTimeout(()=>{                  
      galleryJs.clickaccordinan();              
    },50)
  }

}
