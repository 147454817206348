import { Component, ViewEncapsulation } from '@angular/core';
import { configFile } from './shared/config';
import { Router, NavigationStart, NavigationEnd, RouterOutlet } from '../../node_modules/@angular/router';
import * as $ from 'jquery';
import { fadeAnimation } from './animations';
import { PageComponentComponent } from './page-component/page-component.component';
import { AuthGuard } from './AuthGuard';
  declare var commonFile,  galleryJs;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fadeAnimation
    // animation triggers go here
  ],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  show:boolean;
  lfd:boolean
  private config =new configFile();
  constructor(private router : Router){
    this.router.config.unshift(   
    
      { path: 'page/:id', component: PageComponentComponent ,canActivate: [AuthGuard] },         
    );
  
    this.router.events.subscribe((event) => {
      
      if (event instanceof NavigationEnd) { 
        
        this.config.addscssfile('../../assets/css/main.css','css')
if(event.url=='/' || event.url=='/home')
{
    this.config.removejscssfile('../../assets/css/master.css','css')

    this.config.removejscssfile('../../assets/stylesheet/flexslider.css','css')
  //this.config.addscssfile('../../assets/js/myscript.js', 'js')
    this.config.removejscssfile('../../assets/js/highslide-with-gallery.js','js')
    this.config.removejscssfile('../../assets/js/lightbox-plus-jquery.min.js','js')
    this.show=false;
  }
  else if(event.url=='/lfd'){
    this.show=null;
    this.config.removejscssfile('../../assets/css/main.css','css')

    this.config.removejscssfile('../../assets/stylesheet/master.css','css')
  }
  else { 


      this.config.addscssfile('../../assets/css/master.css','css')
      this.config.addscssfile('../../assets/stylesheet/gallery.css','css')
      this.config.addscssfile('../../assets/stylesheet/flexslider.css','css')
      this.config.addscssfile('../../assets/stylesheet/highslide.css','css')
      this.config.addscssfile('../../assets/css/jquery.fancybox.css','css')
  // this.config.addscssfile('../../assets/js/myscript.js', 'js')
  // this.config.addscssfile('../../assets/js/highslide-with-gallery.js','js')
  // this.config.addscssfile('../../assets/js/jquery.flexslider.js','js')

  //   this.config.addscssfile('../../assets/js/lightbox-plus-jquery.min.js','js')
    this.show=true; 
  }
  
  }
})
  }
  
  onActivate(event) {
    
    window.scrollTo(0,0);    
}
prepareRoute(outlet: RouterOutlet) {
  return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
}
}
