import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { configFile } from '../../shared/config';
import { SharedService } from '../../shared/gallery.service';
declare var galleryJs 

@Component({
  selector: 'app-toppers',
  templateUrl: './toppers.component.html',
  styleUrls: ['./toppers.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class ToppersComponent implements OnInit {

  config
  ToppersList
  ToppersList1
  constructor(private service:SharedService) {  
    this.config =new configFile();   
    this.service.getToppersData().subscribe(data=>{
      if(data.statusCode==200){ 
        if(data.data.length!=0){
          
        this.ToppersList= data.data.filter(element =>element.class.split("-", 1)[0]==="X" || element.class.split("-", 1)[0]==="10"); 
        this.ToppersList1=data.data.filter(element =>element.class.split("-", 1)[0]==="XII" || element.class.split("-", 1)[0]==="12");         
        setTimeout(() => {
          galleryJs.Load10Toppers();
          galleryJs.Load12Toppers();
         }, 200);
      
        }
        else
        this.ToppersList=null;
      }else{
        this.ToppersList=null;
      }
    })  
  }

  ngOnInit() {
  }

}
