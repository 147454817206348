import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared/gallery.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { configFile } from '../shared/config';

@Component({
  selector: 'app-notice-detail',
  templateUrl: './notice-detail.component.html',
  styleUrls: ['./notice-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NoticeDetailComponent implements OnInit {
  private id: number;
  private route$: Subscription;
  NoticeList: any[];
  Noticedata: any;
  noticeUrl
  constructor(private service: SharedService, private activeroute: ActivatedRoute) { }

  ngOnInit() {
this.noticeUrl=new configFile().noticeUrl;
    this.route$ = this.activeroute.params.subscribe(

      (params: Params) => {

        this.id = +params["id"]; // cast to number
        if (this.id) {
  
          this.service.GetSingleNoticeById(this.id).subscribe(data => {
            window.scrollTo(0,0);   
            this.Noticedata = data.data[0];
            this.service.getOtherNotice(this.id).subscribe(data => {
              this.NoticeList = data.data;
       
            })
          })
        }

      }
    );

  }
  
}
