import { DomSanitizer } from "../../../node_modules/@angular/platform-browser";
import { PipeTransform, Pipe } from "../../../node_modules/@angular/core";

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {    
    var ur=url.split('?')
    url=ur[0]+'?rel=0&amp;controls=1&amp;showinfo=0';  
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
