import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared/gallery.service';
import { configFile } from '../shared/config';
declare var galleryJs
 var selectedIndex: any;
@Component({
  selector: 'app-lfd',
  templateUrl: './lfd.component.html',
  styleUrls: ['./lfd.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class LFDComponent implements OnInit {
  selectedIndex=0;
  sliderArray: object[];
  transform: number;
header:boolean;
noticeWithHead=[];
lfdTime:any[];
FlyerList;
AchievementList;
PhotoAlbumList;
studentBirthDay;
teacherBirthDay
 config;
 topper;
 NoticeFooter
  constructor(private data: SharedService) {
    this.sliderArray = [];
    this.header=false;
    this.transform = 100;
    this.config=new configFile();
    this.config.AWSUrl=this.config.AWSUrl+"Download"
  }

  ngOnInit() {
   
    //galleryJs.showlfd();
   
      galleryJs.LoadStuBirthDay()
   
    this.data.getLFDData().subscribe((result: any) => {
      debugger
      if(result.data){
      this.sliderArray = result.data.plm_LatterHead;
      if(this.sliderArray.length>0){
        setTimeout(() => {
          this.config.addscssfile('../../assets/js/shine.js','js')   
          galleryJs.showlfd()      
        
        }, 500);
      }
     this.noticeWithHead=result.data.noticeWithHead;    
     if(this.noticeWithHead.length>0){
      setTimeout(() => {
        galleryJs.LoadNoticeLFD()
      }, 500);
    }

       this.NoticeFooter = result.data.noticeLFD;
       this.FlyerList = result.data.lfDflyer;
       if(this.FlyerList.length>0){
        setTimeout(() => {
          galleryJs.LoadFlyerLFD()
        }, 500);
      }
     
       this.AchievementList=result.data.plm_Achievements
       if(this.AchievementList.length>0){
        setTimeout(() => {
          galleryJs.LoadAchievementLFD()
        }, 500);
      }
       this.PhotoAlbumList=result.data.photoAlbum
       if(this.PhotoAlbumList.length>0){
        setTimeout(() => {
          galleryJs.LoadAlbumLFD()
        }, 500);
      }
       this.studentBirthDay=result.data.studentBirthDay
       if(this.studentBirthDay.length>0){
        setTimeout(() => {
          galleryJs.LoadStuBirthLFD()
        }, 500);
      }
       this.topper=result.data.topper
       if(this.topper.length>0){
        setTimeout(() => {
          galleryJs.LoadTopperLFD()
        }, 500);
      }
       this.teacherBirthDay=result.data.teacherBirthDay
       if(this.teacherBirthDay.length>0){
        setTimeout(() => {
          galleryJs.LoadTeaBirthLFD()
        }, 500);
      }
       

      galleryJs.LoadStuBirthDay();
     
      setTimeout(() => {
        galleryJs.LoadNoticeLFD()
        // galleryJs.LoadStuBirthDay();
      }, 2000);
     
   
    }    
    });
  
  }

  // showlfd()
  // {
  //   debugger
  // var i; 
    
  //     // get the array of divs' with classname image-sliderfade 
  //     var slides = document.getElementsByClassName("image-sliderfade");  
        
  //     // get the array of divs' with classname dot 
  //     var dots = document.getElementsByClassName("dot");  
    
  //     for (i = 0; i < slides.length; i++) { 
  //         // initially set the display to  
  //         // none for every image. 
  //         slides[i].style.display = "none";  
  //     } 
  //    if(!selectedIndex)
  //    selectedIndex=0;
  //      // increase by 1, Global variable 
  //      selectedIndex++;  
  //    if (selectedIndex==0 || selectedIndex==1){
  //     this.header=false;
  //    }else{
  //     this.header=true;
  //    }
  //      // check for boundary 
  //     if (selectedIndex > slides.length)  
  //     { 
  //       selectedIndex = 0;   
  //     } 
     
  //     for (i = 0; i < dots.length; i++) { 
  //         dots[i].className = dots[i].className. 
  //                             replace(" active", ""); 
  //     } 
  //     var time=0;
  //    if(slides[selectedIndex - 1]){
  //     time= parseInt(slides[selectedIndex-1].attributes[1].value)
  //      slides[selectedIndex - 1].style.display = "block"; 
  //     dots[selectedIndex - 1].className += " active"; 
  //    }
    
  //     // Change image every 2 seconds 
  //     setInterval(() =>this.showlfd(), time);
  //     // setTimeout(() =>, time);
    
     
  // }
  
}
