import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { configFile } from '../shared/config';
import { SharedService } from '../shared/gallery.service';
declare var galleryJs
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  show
sliderList
config
buttonList
  constructor(private _service:SharedService) { 
    this.config =new configFile();  
    this._service.getButtonList().subscribe(data=>{
      if(data.statusCode==200){
        this.buttonList= new Array<buttonModel>();
        for (let index = 0; index < data.data.length; index++) {
          var model=new buttonModel();
          model.buttonName =data.data[index].buttonName;
          model.cssClass =data.data[index].cssClass;
          model.pageId =data.data[index].pageId;
       if(data.data[index].link.includes("http") || data.data[index].link.includes("javascript"))
       {
        model.isExternel=true;
        model.link =data.data[index].link;
       }
       else
       {  model.isExternel=false;
          if(model.pageId!=null){
           model.link ="/p/"+data.data[index].link;
          }else{
            model.link =data.data[index].link;
          }
       }
          model.target =data.data[index].target==0?"_self":"_blank";
        
          this.buttonList.push(model);
     
        }
        console.log(this.buttonList)
      }
    })
    this._service.getflyer().subscribe(data=>{
      if(data.statusCode==200){
        if(data.data==true){
        this.show=true;
        }
        else
        this.show=false;
      }else{
        this.show=false;
      }
    }) 

    
  }

  ngOnInit() {
    this._service.getHomeSlider().subscribe(data=>{
      if(data.statusCode==200){
        
       this.sliderList=data.data;
      setTimeout(() => {
        galleryJs.LoadMainSlider();    
      }, 100); 
      }
        
    }) 

  
  }

}
export class buttonModel{
 
pageId: string;
buttonName: string;
link: string;
target: string;
cssClass: string;
isExternel:boolean
}