


import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared/gallery.service';
import * as $ from 'jquery';
import { configFile } from '../shared/config';
declare var commonFile, galleryJs;
var scrollLock = false;
@Component({
  selector: 'app-album-images',
  templateUrl: './albumphotos.component.html',
  styleUrls: ['./albumphotos.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AlbumphotosComponent implements OnInit {
  configFile = new configFile();
  galleryImg = this.configFile.galleryImg;
  images = []; recordStatus = 'Load More';
  page = 1; noDataFound = 0; errImgMsg; albumId; albumName;
  id; eventOn; updatedOn; totalPhoto; description;

  constructor(private imagesService: SharedService, private router: ActivatedRoute) { }
  ngOnInit() {
    var self = this;
    this.router.params.subscribe(data => {
      this.id = data["id"];
      this.imagesService.getAlbumById(this.id).subscribe(
        data => {
          var record = data.data;
          this.albumName = record[0].albumName;
          this.eventOn = record[0].eventOn
          this.updatedOn = record[0].updatedOn
          this.totalPhoto = record[0].totalPhoto
          this.description = record[0].description
        },
        error => {

        }
      )
    })
    // this.albumId = parseInt(this.router.snapshot.paramMap.get('id'));
    this.loadImages(this.id);
    setTimeout(function () {
      $(window).scroll(function () {
        if (!scrollLock) {
          $('.pageLoaders').each(function () {
            if (commonFile.isScrolledIntoView($(this))) {
              if (self.recordStatus == 'Load More') {
                scrollLock = true;
                self.loadImages(self.id)
              }
            }
          });
        }
        if (self.noDataFound != 0) { $('#loader').css('display', 'none'); $('#loadmore').text('No more Records found'); }
      });
    }, 3000)
  }
  noRecordFound() {
    $('#loader').css('display', 'none');
    this.recordStatus = 'No more Records found';
    this.noDataFound++;
  }
  public arr;
  loadImages(albumId) {


    if (this.noDataFound == 0) {

      this.imagesService.getImagesByPage(albumId, this.page).subscribe(
        data => {

          this.recordStatus = 'Load More';
          $('#loader').css('display', 'none');
          this.arr = data.data;
          if (this.arr.length > 0) {
            for (let i = 0; i < this.arr.length; i++) {
              this.images.push(this.arr[i]);
            }

            galleryJs.initImages();
            this.errImgMsg = '';
            this.page++;
          }
          else {
            this.noRecordFound()
          }
          setTimeout(function () {
            scrollLock = false;
          }, 1000)
        },
        error => this.errImgMsg = error
      )
    }
  }

  loadMore(txt) {
    debugger
    if (txt == 'Load More') {
      this.loadImages(this.id);
    }
    else {
      this.noRecordFound()
    }
  }

}
