import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared/gallery.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SafePipe } from '../shared/safepipe';
import * as $ from 'jquery';
import { configFile } from '../shared/config';
declare var commonFile, galleryJs;
@Component({
  selector: 'app-video-gallery-detail',
  templateUrl: './video-gallery-detail.component.html',
  styleUrls: ['./video-gallery-detail.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VideoGalleryDetailComponent implements OnInit {
  videos = [];
  constructor(private router: Router, private route: ActivatedRoute, private service: SharedService, public sanitizer: DomSanitizer) { }
  configFile = new configFile();
  ngOnInit() {
    this.route.params.subscribe(data => {

      var id = data["id"];
      this.getVideoById(id);
      // this.configFile.addscssfile('../../assets/js/jcarousellite_1.0.1c4.js', 'js')
      //this.configFile.addscssfile('../../assets/css/lightslider.css', 'css')
      //this.configFile.addscssfile('../../assets/js/lightslider.js', 'js')
      //this.configFile.addscssfile('../../assets/js/jquery.flexslider.js', 'js')
     
      //this.configFile.addscssfile('../../assets/js/displayVideo.js', 'js')
      //this.configFile.addscssfile('../../assets/js/myscript.js', 'js')
   
 
    }, error => { })

  }

  albumDiscription; albumIconPhoto; albumName; dataOfAccess; dateOfCreation; totalRec; totalVideo;
  getVideoById(id) {
    this.service.getVideoSingleDetail(id).subscribe(
      data => {
        if (data.data.length > 0) {
          var arr = data.data;
          this.albumDiscription = arr[0].albumDiscription;
          this.albumIconPhoto = arr[0].albumIconPhoto;
          this.albumName = arr[0].albumName;
          this.dataOfAccess = arr[0].dataOfAccess;
          this.dateOfCreation = arr[0].dateOfCreation;
          this.totalRec = arr[0].totalRec;
          this.totalVideo = arr[0].totalVideo;
  
          this.getAllVideoOflbum(id);
        }
      },
      error => {

      }
    )
  }
  youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}
  getAllVideoOflbum(id) {
    this.service.getAllVideoOfAlbum(id).subscribe(
      data => {
debugger
        this.videos = data.data;
        for (var i = 0; i < this.videos.length; i++) {
          var output =this.youtube_parser(this.videos[i].albumVideoPath)
          var url='http://img.youtube.com/vi/' + output + '/0.jpg'
          this.videos[i].thumb =url;         
        }
      
        setTimeout(() => {
          galleryJs.videoDetail();
        }, 500);
      },
      error => {

      }
    )
  }
}
