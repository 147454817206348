

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedService } from '../shared/gallery.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import { configFile } from '../shared/config';
declare var commonFile;
@Component({
  selector: 'app-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class AchievementComponent implements OnInit {

  scrollLock = false;
  configFile = new configFile();
  imgUrl = this.configFile.achievementsImg; pipe = new DatePipe('en-US');
  achievements = []; errYearMsg = ''; errorSearchMsg = ''; errRecordMsg = ''; errSearchMsg = '';
  detailedAchievement = { EventVenue: '', description: '', EventDate: '', RowNum: '', };
  filterApplied = 0; TotalAchievements; noDataFound = 0; page = 1; errorMsg = ''; searchBy = '1'; years = [];
  allSearch = ''; AlbumTitle = ''; year = ''; eventDate = ''; Descriptions = ''; text = '';
  recordStatus = 'Load More';
  constructor(private service: SharedService) { }

  ngOnInit() {
    //this.loadAchievements();
    var self = this;
    // this.loadYear();

    setTimeout(function () {
      // $(window).scroll(function () {
      //   if (!this.scrollLock) {
      //     $('.pageLoaders').each(function () {
      //       if (commonFile.isScrolledIntoView($(this))) {
      //         if (self.recordStatus == 'Load More') {
      //           this.scrollLock = true;
      //           if (self.filterApplied == 0) { self.loadAchievements() }
      //           else {
      //             self.searchAlbum(self.page);
      //           }
      //         }


      //       }
      //     });
      //   }
      //   if (self.noDataFound != 0) {
      //     $('#loader').css('display', 'none');
      //     self.recordStatus = 'No more Records found';
      //   }
      // });
    }, 3000)
  }
  loadYear() {

    this.service.achievementsYear().subscribe(data => {

      if (data.statusCode == 200 && data.errorMessage == 'OK') {
        this.years = data.data;
        this.errYearMsg = '';
      }
    },
      error => {
        this.errYearMsg = error.message
      }
    )
  }

  searchAlbum(page) {

    this.page = page;
    if (this.searchBy == '1') this.text = this.allSearch
    else if (this.searchBy == '2') this.text = this.AlbumTitle
    else if (this.searchBy == '3') this.text = this.year
    else if (this.searchBy == '4') {
      var d;
      if (this.eventDate != null) {
        d = this.pipe.transform(this.eventDate, 'dd-MMM-yyyy');
      }
      else { d = '' }
      this.text = d;
    }
    else if (this.searchBy == '5') this.text = this.Descriptions;

    if (!this.text) {
      this.noDataFound = 0; this.filterApplied = 0; this.achievements = [];
      this.loadAchievements();
      return false
    }

    this.filterApplied++;
    this.service.searchAchievements(this.searchBy, this.text, this.page).subscribe(
      data => {

        if (data.statusCode == 200 && data.errorMessage == 'OK') {
          debugger
          var arr = data.data;
          this.TotalAchievements = data.data[0].totalRecord;
          if (arr.length > 0 || this.page == 1) {
            if (this.page == 1) { this.achievements = []; }
            for (let i = 0; i < arr.length; i++) {
              this.achievements.push(arr[i]);
            }
         
            this.errorSearchMsg = '';
            this.page++;
          }
          else {
            this.noRecordFound()
          }
          setTimeout(function () {
            this.scrollLock = false;
          }, 1000)
        }
        else {
          this.noRecordFound()
        }
      },
      error => {
        this.errorSearchMsg = error.message;
      }
    )
  }
  getDetail(id) {
    for (let i = 0; i < this.achievements.length; i++) {
      if (id == this.achievements[i].aId) {
        this.detailedAchievement = Object.assign({}, this.achievements[i]);
        break;
      }
    }
    $(window).scrollTop(0)
  }
  loadAchievements() {

    if (this.noDataFound == 0) {
      this.service.getAchievements(this.page).subscribe(
        data => {
          
          if (data.statusCode == 200 && data.errorMessage == 'OK') {
            
            var arr = data.data;
           if(arr.length > 0)
           {
            this.TotalAchievements = data.data[0].totalRecord;
           }
           debugger
            if (arr.length > 0 || this.page == 1)
             {
              if (this.page == 1) { this.achievements = []; }
              $('#loader').css('display', 'none');
              $('#loadmore').text('Load More');

              for (let i = 0; i < arr.length; i++) {
                this.achievements.push(arr[i]);
              }
            //  this.TotalAchievements = this.achievements.length;

              this.errRecordMsg = '';
              this.detailedAchievement = Object.assign({}, this.achievements[0]);
              this.page++;
            }
            else {
              $('#loadmore').text('No more Records found');
              this.noRecordFound();
            }
            setTimeout(function () {
              this.scrollLock = false;
            }, 1000)
          }
          else {
            this.noRecordFound();
          }
        },
        error => this.errRecordMsg = error.message
      )
    }
  }

   noRecordFound() {
    $('#loader').css('display', 'none');
    this.recordStatus = 'No more Records found';
    this.noDataFound++;

  }
  loadMore(txt) {
    debugger
    var self = this;
    if (txt == 'Load More') {
      if (this.filterApplied == 0) { this.loadAchievements() }
      else { this.searchAlbum(self.page); }
    }
    else {
      this.noRecordFound()
    }
  }

}
