import { Component, OnInit, ViewChild, Input } from '@angular/core';
import * as $ from 'jquery';
import * as moment from 'moment';
import 'fullcalendar';
import { SharedService } from '../shared/gallery.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-calender',
  templateUrl: './event-calender.component.html',
  styleUrls: ['./event-calender.component.css']
})
export class EventCalenderComponent implements OnInit {
  @Input()
  @Input() eventData: any[];
   loading:boolean
  defaultConfigurations: any;
  set configurations(config: any) {
     if(config) {
      this.defaultConfigurations = config;  
     }
  }

constructor(private _service: SharedService,private router:Router) {
  this.loading=false;
this._service.getEventsAll().subscribe((result: any)=>{
  if(result.data){
    this.eventData=[];    
    console.log(result)
    result.data.forEach(element => {
        this.eventData.push({title: element.activityTitle,
        start:new Date(element.date),
        end: new Date(element.tillDate)
      })
     });
     setTimeout(()=>{
      this.loading=true;
      this.defaultConfigurations = {
        editable: true,
             eventLimit: true,
             titleFormat: 'MMM D YYYY',
             header: {
                left: 'prev,next today',
                center: 'title',
                right: 'month,agendaWeek,agendaDay'
             },
             buttonText: {
                today: 'Today',
                month: 'Month',
                week: 'Week',
                day: 'Day'
             },
             views: {
                agenda: {
                   eventLimit: 1
                }
             },
                dayClick: (date, jsEvent, activeView) => {
                  this.dayClick(date, jsEvent, activeView);
              },
              
              eventDragStart: (timeSheetEntry, jsEvent, ui, activeView) => {
                  this.eventDragStart(
                      timeSheetEntry, jsEvent, ui, activeView
                  );
              },
            eventDragStop: (timeSheetEntry, jsEvent, ui, activeView) => {
                  this.eventDragStop(
                    timeSheetEntry, jsEvent, ui, activeView
                  );
              },
             allDaySlot: false,
             slotDuration: moment.duration('00:15:00'),
             slotLabelInterval: moment.duration('01:00:00'),
             firstDay: 1,
             selectable: true,
             selectHelper: true,
             events: this.eventData,
          };
      $('#full-calendar').fullCalendar(
        this.defaultConfigurations
        
     );
     },1000)


  }
})


}

dayClick(date, jsEvent, activeView) {
  var month = new Array();
month[0] = "January";
month[1] = "February";
month[2] = "March";
month[3] = "April";
month[4] = "May";
month[5] = "June";
month[6] = "July";
month[7] = "August";
month[8] = "September";
month[9] = "October";
month[10] = "November";
month[11] = "December";
var n = month[date._d.getMonth()];
var y = new Date(date._d).getFullYear()
 debugger
  console.log(n);
  this.router.navigate(['/event-display-month/'+n]);

}
eventDragStart(timeSheetEntry, jsEvent, ui, activeView) {
  console.log('event drag start');
}
eventDragStop(timeSheetEntry, jsEvent, ui, activeView) {
  console.log('event drag end');
}
ngOnInit() { 

  }
}

