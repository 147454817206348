import { Component, OnInit, NgZone, HostBinding, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared/gallery.service';
import {GooglePlacesDirective} from '../google-places.directive';
declare var galleryJs;
import * as $ from 'jquery';

@Component({
    selector: 'app-reach-us',
    templateUrl: './reach-us.component.html',
    styleUrls: ['./reach-us.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ReachUsComponent implements OnInit {

   // @HostBinding('class.is-open')
    public title:string;
  
    public addrKeys: string[];
    public addr: object;
    
    lat; lng;
    currentLat; currentLong; map; marker;
    public origin: {}
    public destination: {}
    getDirection(Srclat, Srclng, dstLat, dstLng) {        
        this.origin = { lat: Srclat, lng: Srclng}
        this.destination = { lat: dstLat, lng: dstLng }
    }    
    address
    constructor(private imagesService: SharedService, private zone: NgZone) {   this.title='Places'}
    setAddress () {      
      debugger       
      this.zone.run(() => {            
          this.addr = this.address;
          this.addrKeys = Object.keys(this.address);
      });
  }
  public renderOptions = {
    suppressMarkers: true,
}

public markerOptions = {
    origin: {
        icon: 'https://i.imgur.com/7teZKif.png',
        
    },
    destination: {
        icon: 'https://i.imgur.com/7teZKif.png',
        infoWindow: '<img src="http://www.tinytotsschool.org/images/map.png">'
    },
}
    ngOnInit() {
      // setTimeout(() => {
      //   galleryJs.initialize();
      // }, timeout);
     
        this.imagesService.change.subscribe(parameter => {
            this.getDirection(parameter.lat, parameter.lng, 26.060321, 83.187235)
        });
        this.findMe(); 
    }
     
    findMe() {             
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) =>
             {                
                this.showPosition(position);
           
            });
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }
    showPosition(position) {        
        this.currentLat = position.coords.latitude;
        this.currentLong = position.coords.longitude;
        this.getDirection(this.currentLat, this.currentLong, 26.060321, 83.187235)
    }
}

export class LatLng {
    lat: number;
    lng: number;
}