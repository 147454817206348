import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DefaultFooterComponent } from './default-footer/default-footer.component';
import { DefaultHeaderComponent } from './default-header/default-header.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import {SharedService} from './shared/gallery.service';
import { NOticeComponent } from './Controls/notice/notice.component'
import { HttpClientModule } from '../../node_modules/@angular/common/http';
import { RouteModule } from './route/route.module';
import { RouterModule, Routes } from '@angular/router';
import { PhotogalleryComponent } from './Controls/photogallery/photogallery.component';
import { StudentBirthdayComponent } from './Controls/student-birthday/student-birthday.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AlbumphotosComponent } from './albumphotos/albumphotos.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NoticeComponent } from './notice/notice.component';
import { NoticeDetailComponent } from './notice-detail/notice-detail.component';
import { AchievementComponent } from './achievement/achievement.component';
import { AchievementDetailComponent } from './achievement-detail/achievement-detail.component';
import { MediaGalleryComponent } from './media-gallery/media-gallery.component';
import { MediaGalleryDetailComponent } from './media-gallery-detail/media-gallery-detail.component';
import { VideoGalleryDetailComponent } from './video-gallery-detail/video-gallery-detail.component';
import { VideoGalleryComponent } from './video-gallery/video-gallery.component';
import { SafePipe } from './shared/safepipe';
import { MailusComponent } from './mail-us/mail-us.component';
import { MatNativeDateModule, MatFormFieldModule, MatSelect, MatSelectModule, MatInputModule } from '@angular/material';
import { ReachUsComponent } from './reach-us/reach-us.component';
import { SiteMapComponent } from './site-map/site-map.component';
import { WriteUsComponent } from './write-us/write-us.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivitiesComponent } from './activities/activities.component';
import { GooglePlacesDirective } from './google-places.directive';
import { FlyerComponent } from './Controls/flyer/flyer.component';
import { AgmCoreModule } from '@agm/core';            // @agm/core
import { AgmDirectionModule } from 'agm-direction';   // agm-direction
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AlertModule } from './ngx-alerts/src/public_api';
import { RangePipe } from './range.pipe';
;
import { LFDComponent } from './lfd/lfd.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { InfrastructureComponent } from './infrastructure/infrastructure.component';
import { VisionAndMissionComponent } from './vision-and-mission/vision-and-mission.component';
import { SchoolAnthemComponent } from './school-anthem/school-anthem.component';
import { OrganizationOfSchoolComponent } from './organization-of-school/organization-of-school.component';
import { CurriculumComponent } from './curriculum/curriculum.component';
import { AcademicsComponent } from './academics/academics.component';
import { CoScholasticAreasComponent } from './co-scholastic-areas/co-scholastic-areas.component';
import { SchoolFeesComponent } from './school-fees/school-fees.component';
import { SchoolTimingsComponent } from './school-timings/school-timings.component';
import { SchoolRulesComponent } from './school-rules/school-rules.component';
import { RuleRegulationsComponent } from './rule-regulations/rule-regulations.component';
import { PrincipalMessageComponent } from './principal-message/principal-message.component';
import { VirtualTourComponent } from './virtual-tour/virtual-tour.component';
import { VsmsComponent } from './vsms/vsms.component';
import { PrayerComponent } from './prayer/prayer.component';
import { HolidayListComponent } from './holiday-list/holiday-list.component';
import { ExaminationsScheduleComponent } from './examinations-schedule/examinations-schedule.component';
import { FeeStructureComponent } from './fee-structure/fee-structure.component';
import { GroupPhotosComponent } from './group-photos/group-photos.component';
import { TeachingStaffComponent } from './teaching-staff/teaching-staff.component';
import { SchoolCouncilComponent } from './school-council/school-council.component';
import { HeadBoyGirlComponent } from './head-boy-girl/head-boy-girl.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { EventCalenderComponent } from './event-calender/event-calender.component';
import { EventDisplayMonthComponent } from './event-display-month/event-display-month.component';
import { EventDisplayAllComponent } from './event-display-all/event-display-all.component';
import { ToppersComponent } from './Controls/toppers/toppers.component';
import { BotDetectCaptchaModule } from 'angular-captcha';
import{AuthGuard} from './AuthGuard';
import { PageComponentComponent } from './page-component/page-component.component';
const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
  {
    path: 'q/:id',
    component: PageComponentComponent,canActivate: [AuthGuard]
  },
  {
    path: 'q /:id',
    component: PageComponentComponent,canActivate: [AuthGuard]
  },
  { path: 'about-us', component: AboutUsComponent,data:{animation:'isRight'} },
  { path: 'photo-gallery', component: PhotoGalleryComponent },
  { path: 'photo-gallery-details/:id', component: AlbumphotosComponent },
  { path: 'notice', component: NoticeComponent },
  { path: 'getnoticedetail/:id', component: NoticeDetailComponent },
  { path: 'achievement-detail/:id', component: AchievementDetailComponent },
  { path: 'achievements', component: AchievementComponent },
  { path: 'media-gallery', component: MediaGalleryComponent },
  { path: 'media-gallery-detail/:id', component: MediaGalleryDetailComponent },
  { path: 'video-gallery', component: VideoGalleryComponent },  
  { path: 'video-gallery-detail/:id', component: VideoGalleryDetailComponent },
  { path: 'reach-us', component: ReachUsComponent },
  { path: 'mail-us', component: MailusComponent },
  { path: 'site-map', component: SiteMapComponent },
  { path: 'write-us', component: WriteUsComponent},
  { path: 'activities', component: ActivitiesComponent},
   { path: 'lfd', component: LFDComponent},
   { path: 'infrastructure', component: InfrastructureComponent},
   { path: 'vision-and-mission', component: VisionAndMissionComponent},
   { path: 'school-anthem', component: SchoolAnthemComponent},
   { path: 'organization-of-school', component: OrganizationOfSchoolComponent},
   { path: 'curriculum', component: CurriculumComponent},
   { path: 'academics', component: AcademicsComponent},
   { path: 'co-scholastic-areas', component: CoScholasticAreasComponent},
   { path: 'school-fees', component: SchoolFeesComponent},
   { path: 'school-timings', component: SchoolTimingsComponent},
   { path: 'school-rules', component: SchoolRulesComponent},
   { path: 'rule-regulations', component: RuleRegulationsComponent},
   { path: 'principal-message', component: PrincipalMessageComponent},
   { path: 'virtual-tour', component: VirtualTourComponent},
   { path: 'vsms', component: VsmsComponent},
   { path: 'prayer', component: PrayerComponent},
   { path: 'holiday-list', component: HolidayListComponent},
   { path: 'examinations-schedule', component: ExaminationsScheduleComponent},
   { path: 'fee-structure', component: FeeStructureComponent},
   { path: 'group-photos', component: GroupPhotosComponent},
   { path: 'teaching-staff', component: TeachingStaffComponent},
   { path: 'school-council', component: SchoolCouncilComponent},
   { path: 'head-boy-girl', component: HeadBoyGirlComponent},
   { path: 'coming-soon', component: ComingSoonComponent},
   { path: 'event-calender', component: EventCalenderComponent},

   { path: 'event-display-month/:month', component: EventDisplayMonthComponent},
   { path: 'event-display-all', component: EventDisplayAllComponent}
   

   

   
   
   
];


  



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DefaultFooterComponent,
    DefaultHeaderComponent,
    HomeComponent,
    PageComponentComponent ,
    AboutUsComponent,
    NOticeComponent,
    PhotogalleryComponent,
    StudentBirthdayComponent,
    PhotoGalleryComponent,
    AlbumphotosComponent,
    NoticeComponent,
    NoticeDetailComponent,
    AchievementComponent,
    AchievementDetailComponent,
    MediaGalleryComponent,
    MediaGalleryDetailComponent,
    VideoGalleryDetailComponent,
    VideoGalleryComponent,
    SafePipe,
    MailusComponent, 
    ReachUsComponent,
    SiteMapComponent,
    WriteUsComponent,
    ActivitiesComponent,
    GooglePlacesDirective,
    FlyerComponent,
    RangePipe,
    LFDComponent,
    InfrastructureComponent,
    VisionAndMissionComponent,
    SchoolAnthemComponent,
    OrganizationOfSchoolComponent,
    CurriculumComponent,
    AcademicsComponent,
    CoScholasticAreasComponent,
    SchoolFeesComponent,
    SchoolTimingsComponent,
    SchoolRulesComponent,
    RuleRegulationsComponent,
    PrincipalMessageComponent,
    VirtualTourComponent,
    VsmsComponent,
    PrayerComponent,
    HolidayListComponent,
    ExaminationsScheduleComponent,
    FeeStructureComponent,
    GroupPhotosComponent,
    TeachingStaffComponent,
    SchoolCouncilComponent,
    HeadBoyGirlComponent,
    ComingSoonComponent,
	SafeHtmlPipe,
	EventCalenderComponent,
	EventDisplayMonthComponent,
	EventDisplayAllComponent,
  ToppersComponent,
  

  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,  
   
   // RouterModule.forRoot([]),
    FormsModule,
    BsDatepickerModule.forRoot(),
    MatNativeDateModule,
    MatFormFieldModule, MatSelectModule, MatInputModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
      AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBu-916DdpKAjTmJNIgngS6HL_kDIKU0aU',
      //apiKey: 'AIzaSyB2YxtJ8gt3EGWoCmBMzX1ZLjx7N4wOs-Y',      
      }),
     AgmDirectionModule,AlertModule.forRoot(),
     AgmSnazzyInfoWindowModule,
     AgmJsMarkerClustererModule,
    BrowserAnimationsModule,
    BotDetectCaptchaModule.forRoot({
      captchaEndpoint: 
        'http://www.inspirationschoolkgm.org/captcha.ashx'
    })
   
 
  ],
  providers: [SharedService, AuthGuard,{provide: LocationStrategy, useClass: HashLocationStrategy}],

  bootstrap: [AppComponent],
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA,
  //   NO_ERRORS_SCHEMA
  // ]

})
export class AppModule {
  
 
  
 
 }
