import { Component, OnInit, ViewEncapsulation, Renderer2 } from '@angular/core';
import { configFile } from '../../shared/config';
import { SharedService } from 'src/app/shared/gallery.service';
declare var galleryJs 

@Component({
  selector: 'app-flyer',
  templateUrl: './flyer.component.html',
  styleUrls: ['./flyer.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class FlyerComponent implements OnInit {
  config
  constructor(private render: Renderer2,private _service:SharedService){
   
  }

  ngOnInit() {
  }
  ngAfterViewInit(){
    const classArr: any = document.querySelectorAll('.modalBackground');
    classArr.forEach(element=>{
      this.render.listen(element, 'click', (target)=>{
        galleryJs.HideFlyer(0);
      })
    });
    
  }
}
