import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { configFile } from '../../shared/config';
import { SharedService } from '../../shared/gallery.service';
declare var galleryJs 
@Component({
  selector: 'app-student-birthday',
  templateUrl: './student-birthday.component.html',
  styleUrls: ['./student-birthday.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class StudentBirthdayComponent implements OnInit {

  BirthList:any
  TeacherList:any
  config:any

  constructor(private service:SharedService) {  
    this.config =new configFile();   
    this.service.getStudentBirthDayToday().subscribe(data=>{
      if(data.statusCode==200){ 
        if(data.data.length!=0){
        this.BirthList = data.data;
        setTimeout(() => {
          galleryJs.LoadStuBirthDay();
         }, 500);
      
        }
        else
        this.BirthList=null;
      }else{
        this.BirthList=null;
      }
    })  
    this.service.getTeacherBirthDayToday().subscribe(data=>{
      if(data.statusCode==200){ 
        if(data.data.length!=0)       
       {
        this.TeacherList = data.data;
        galleryJs.LoadTeachBirthDay();
       }
        else
        this.TeacherList=null;
      }else{
        this.TeacherList=null;
      }

    })   
    
  }

  ngOnInit() {
  }

}
