import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import {  FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../shared/gallery.service';

import { ReCaptcha2Component } from 'ngx-captcha';
import { configFile } from '../shared/config';



declare var commonFile;

@Component({

  selector: 'app-write-us',
  templateUrl: './write-us.component.html',
  styleUrls: ['./write-us.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class WriteUsComponent implements OnInit {
 writeUsForm: FormGroup// = { Name: '', Address: '', Contact: '', EmailID: '', Subject: '', Message: '' }
  submitted = false; recaptcha = ''
  config
  
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string
  captcha
  sitekey: any;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
  constructor(private service: SharedService, private formBuilder: FormBuilder,private cdr: ChangeDetectorRef ) {
    this.config=new configFile();
    
       }

  initialize() {

 this.sitekey =this.config.catpchaKey;
    this.recaptcha = '';
    this.submitted = false;
   
    this.writeUsForm = this.formBuilder.group({
      Name: ['', [Validators.required, Validators.pattern('[a-z A-Z]+')]],
      Profession: ['', [Validators.required, Validators.minLength(3),Validators.pattern('[a-z A-Z]+')]],
      How_U_Know: ['', [Validators.required, ]],
      Email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      Website: [''],
      Rate: ['', Validators.required],
      Comments: ['', [Validators.required, Validators.minLength(20)]],
      EmailFrom:[this.config.Email],
      Password:[this.config.Password],
      Id:'',
      Active:0,
      Date:new Date(),  
      recaptcha: ['',[Validators.required]]   
    })

  }
  reload(): void {
    this.captchaElem.reloadCaptcha();
  }
  get forms() { return this.writeUsForm.controls }
  ngOnInit() {
    this.initialize()
    this.loadComment();
  }
  ngAfterViewInit(): void {
  
  }
  handleSuccess(captchaResponse: string): void {
    this.captcha = null;
    this.captchaSuccess = true;
    this.recaptcha=captchaResponse;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {

    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captcha = "invalid captcha *"
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
  commentList
  loadComment(){
    this.service.getGuestBookComment().subscribe(
    response => { 
      debugger
      this.commentList=response.data;  
    }) 
  }
  submitForm() {
    debugger
        this.submitted = true;       
        if (this.writeUsForm.invalid) { return; }
        else {       
          commonFile.loader(true)      
          this.service.PostWriteUsComment(this.writeUsForm.value).subscribe(
            response => {           
              if (response.statusCode == 200 && response.errorMessage == 'OK') {
                this.initialize();
                this.service.alert('success', 'Thanks for submitting the form.');
                commonFile.loader(false)
              } else {
                commonFile.loader(false)
                this.service.alert('warning', response.errorMessage);
              }
            },
            error => {            
              this.service.alert('warning', 'something went wrong');
              commonFile.loader(false)
            }
          )
            ;
        }
      }
    
}
