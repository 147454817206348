import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SharedService } from '../shared/gallery.service';

@Component({
  selector: 'app-page-component',
  templateUrl: './page-component.component.html',
  styleUrls: ['./page-component.component.css']
})
export class PageComponentComponent implements OnInit {
  title
  data:any;
  constructor(private router : Router,private _Service: SharedService,) {
    debugger
    this.title = this.router.url;
    if(this.title.includes("/q")){
    this.title= this.title.split("/")[2];
    this._Service.getWebPageContent(this.title).subscribe(data => {
      if(data.data.length>0){
      this.data = data.data[0];
      }

    })

    }
  }


  ngOnInit() {

  }

}
