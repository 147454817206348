import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {SharedService} from '../../shared/gallery.service'
import { configFile } from '../../shared/config';
import * as $ from 'jquery';
declare var galleryJs 
 
@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NOticeComponent implements OnInit {
NoticeList:any

private config =new configFile();
  constructor(private service:SharedService) {     
    this.service.getTopTenActiveNotice().subscribe(data=>{
      if(data.statusCode==200){        
        this.NoticeList = data.data;
        
       setTimeout(() => {
        galleryJs.LoadNotice()
       }, 100);

        
      }else{
        this.NoticeList=null;
      }
    })  
    
  }

  ngOnInit() {
   
  }

}
