import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { FormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../shared/gallery.service';
import * as $ from 'jquery';

import { ReCaptcha2Component } from 'ngx-captcha';
import { config } from 'rxjs';
import { configFile } from '../shared/config';

declare var hljs: any;
declare var commonFile;
@Component({
  selector: 'app-mail-us',
  templateUrl: './mail-us.component.html',
  styleUrls: ['./mail-us.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MailusComponent implements OnInit {

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string
  captcha
  sitekey: any;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  @ViewChild('langInput') langInput: ElementRef;
  mailUsForm: FormGroup// = { Name: '', Address: '', Contact: '', EmailID: '', Subject: '', Message: '' }
  submitted = false; recaptcha = ''
  config
  constructor(private service: SharedService, private formBuilder: FormBuilder, private cdr: ChangeDetectorRef, ) {
this.config=new configFile();

   }

  initialize() {

    this.sitekey =this.config.catpchaKey;
    this.recaptcha = '';
    this.submitted = false;
    this.mailUsForm = this.formBuilder.group({
      Name: ['', [Validators.required, Validators.pattern('[a-z A-Z]+')]],
      Address: ['', [Validators.required, Validators.minLength(3)]],
      Contact: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.minLength(10), Validators.maxLength(10)]],
      EmailID: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      Subject: ['', Validators.required],
      Message: ['', Validators.required],
      EmailFrom:[this.config.Email],
      Password:[this.config.Password],
      recaptcha: ['',Validators.required]
    })
  }
  get forms() { return this.mailUsForm.controls }
  ngOnInit() {
    this.initialize()
  }
  ngAfterViewInit(): void {
    this.highlight();
  }

  handleSuccess(captchaResponse: string): void {
    this.captcha = null;
    this.captchaSuccess = true;
    this.recaptcha=captchaResponse;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleLoad(): void {

    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captcha = "invalid captcha *"
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }
  reload(): void {
    this.captchaElem.reloadCaptcha();
  }
  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }
  submitForm() {
debugger
    this.submitted = true;
   
    if (this.mailUsForm.invalid) { return; }
    else {       
      commonFile.loader(true)      
      this.service.PostMailUs(this.mailUsForm.value).subscribe(
        response => {
          this.reload()
          if (response.statusCode == 200 && response.errorMessage == 'OK') {
        
            this.service.alert('success', 'Mail sent successfully,please check your mail');
            commonFile.loader(false)
            this.initialize();
          } else {
            commonFile.loader(false)
            this.service.alert('warning', response.errorMessage);
          }
        },
        error => {
          this.reload()
          this.service.alert('warning', 'something went wrong');
          commonFile.loader(false)
        }
      )
        ;
    }
  }

}
