import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared/gallery.service';

declare var galleryJs
@Component({
  selector: 'app-event-display-all',
  templateUrl: './event-display-all.component.html',
  styleUrls: ['./event-display-all.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class EventDisplayAllComponent implements OnInit {

  constructor(private _service :SharedService) { }
  event:any[]
  ngOnInit() {
    debugger
    this._service.getEventShowAllMonth().subscribe(
             
      data => {          
          if (data.statusCode == 200 && data.errorMessage == 'OK') {     
                 
              this.event=new Array<eventList>()
               for (var i =0;i< data.data.length-1;i++){
                 var gl=new eventList();                 
                 gl.month=data.data[i].month
                 this.event.push(gl);
                } 
                setTimeout(()=>{                  
                  galleryJs.clickaccordinan();              
                },500)

          }
      }
  )
  }
loadmonthEvent(month,index){
  debugger
  if(!this.event[index].monthevent){
    
  this._service.getEventsOfMonth(month).subscribe((result:any)=>{
    //this.listist=result.data;
    var  evelust=new Array<monthevent>();
    for (var i =0;i< result.data.length-1;i++){
      var  photobj=new monthevent();
      photobj.event=result.data[i].event
      photobj.date=result.data[i].date
      photobj.dayName=result.data[i].dayName

      evelust.push(photobj);
    }
    this.event[index].monthevents=evelust;
    })
  }
}
}
export class eventList
{
month:string
monthevents:monthevent;
}
export class monthevent{
  event:string
  date:Date
  dayName:string
  
}