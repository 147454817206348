import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedService } from '../shared/gallery.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';
import { configFile } from '../shared/config';
declare var commonFile, galleryJs;
var scrollLock = false;

@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VideoGalleryComponent implements OnInit {
  configFile = new configFile();
  videoUlr = this.configFile.videoImgUrl; headinglength = 10; latestVideo = []; albumVideos = [];
  filterApplied = 0; TotalVideo; noDataFound = 0; videoPage = 1; Videos = []; errorMsg; searchBy = '1'; years = []; errYearMsg = '';
  allSearch = ''; AlbumTitle = ''; year = ''; eventDate = ''; Descriptions = ''; text = ''; pageInitialize = 0; recordStatus = 'Load More';
  pipe = new DatePipe('en-US'); errSearchMsg = '';
  constructor(private service: SharedService, private router: Router, public sanitizer: DomSanitizer) { }
  public arr;

  ngOnInit(): void {
    
    var self = this;
    //this.loadVideo();
    // this.searchAlbum(this.videoPage);
    this.loadYear();
    this.getLatestVideo();
    $(window).scrollTop(0);
    setTimeout(function () { 
    //this.getLatestVideo()
    $(window).scroll(function () {
      
      if (!scrollLock) {
        $('.pageLoaders').each(function () {
          if (commonFile.isScrolledIntoView($(this))) {
            if (self.recordStatus == 'Load More') {
              scrollLock = true;
              if (self.filterApplied == 0) { self.searchAlbum(self.videoPage) }
              else {
                self.searchAlbum(self.videoPage);
              }
            }
          }
        });
      }
      if (self.noDataFound != 0) { $('#loader').css('display', 'none'); $('#loadmore').text('No more Records found'); }
    });
  },3000)
  }

  loadYear() {
    this.service.getVideoYears().subscribe(data => {
      if (data.statusCode == 200 && data.errorMessage == 'OK') {
        this.years = data.data;
      }
    },
      error => {
        this.errorMsg = error
      }
    )
  }
  loadAlbum(id) {
    this.Videos=[];
    if (this.noDataFound == 0) {
        this.service.getVideoAlbum(this.videoPage,id).subscribe(
           
          data => {

            if (data.statusCode == 200 && data.errorMessage == 'OK') {
              this.arr = data.data;
              if (this.arr.length > 0 || this.videoPage == 1) {
                if (this.videoPage == 1) { this.Videos = []; }
                for (let i = 0; i < this.arr.length; i++) {
                  this.Videos.push(this.arr[i]);
                }
                if ( this.TotalVideo >= 20) {
                  scrollLock=true;
                } else {
               $('#loadmore').text('No more Records found');
               this.noDataFound++;
               scrollLock=false
                }
                this.videoPage++;
           
              
              }
              else {
                this.noRecordFound()
              }
            }
            else {
              this.noRecordFound()
            }
          },
          error => {
            this.errorMsg = error
          }
        )
    }
}

  loadVideo() {
    if (this.noDataFound == 0) {

      this.service.getVideo(this.headinglength).subscribe(
        data => {
          
          if (data.statusCode == 200 && data.errorMessage == 'OK') {
            this.arr = data.data;
            if (this.arr != null && this.arr.length != 0) {
            
              $('#loader').css('display', 'none');
              $('#loadmore').text('Load More');
              for (let i = 0; i < this.arr.length; i++) {
                this.Videos.push(this.arr[i]);
              }
              this.pageInitialize++;
              this.videoPage++;
            }
            else {

              $('#loader').css('display', 'none');
              $('#loadmore').text('No more Records found');
              this.noDataFound++;
            }
            setTimeout(function () {
              scrollLock = false;
            }, 1000)
          }
        },
        error => {

          this.errorMsg = error
        }
      )
    }
  }
  searchAlbum(page) {

    this.videoPage = page;
    if (this.searchBy == '1') this.text = this.allSearch
    else if (this.searchBy == '2') this.text = this.AlbumTitle
    else if (this.searchBy == '3') this.text = this.year
    else if (this.searchBy == '4') {
      var d;
      if (this.eventDate != null) {
        d = this.pipe.transform(this.eventDate, 'dd-MMM-yyyy');
      }
      else { d = '' }
      this.text = d;
    }
    else if (this.searchBy == '5') this.text = this.Descriptions;
    //if (!this.text) {
    //    this.noDataFound = 0; this.videoPage = 1; this.filterApplied = 0; this.Videos = []; this.loadVideo(); return false
    //}

    this.filterApplied++;
    this.service.searchVideoData(this.searchBy, this.text, this.videoPage).subscribe(
      data => {

        if (data.statusCode == 200 && data.errorMessage == 'OK') {
          this.arr = data.data;
          if (this.arr.length > 0 || this.videoPage == 1) {
            if (this.videoPage == 1) { this.Videos = []; }
            for (let i = 0; i < this.arr.length; i++) {
              this.Videos.push(this.arr[i]);
            }
            if (this.arr.length > 0) {
              this.TotalVideo = this.arr[0].totalRec;
            } else {
              this.TotalVideo = this.arr.length;
            }
            this.videoPage++;
       
          
          }
          else {
            this.noRecordFound()
          }
        }
        else {
          this.noRecordFound()
        }
      },
      error => {
        this.errorMsg = error
      }
    )
  }

  id; albumName; dateOfCreation; dataOfAccess; totalVideo; albumDiscription
  getLatestVideo() {
    this.service.getLatestVideoAlbum(50).subscribe(
      data => {
        debugger
        if (data.statusCode == 200 && data.errorMessage == 'OK') {
          this.arr = data.data;
          
          var id = this.arr[0].id;
          this.id = this.arr[0].id
          this.albumName = this.arr[0].albumName
          this.dateOfCreation = this.arr[0].dateOfCreation;
          this.dataOfAccess = this.arr[0].dataOfAccess;
          this.totalVideo = this.arr[0].totalVideo;
          this.TotalVideo = this.arr[0].totalRec;
          this.albumDiscription = this.arr[0].albumDiscription;
          this.getAllVideoOflbum(id);
          this.loadAlbum(id)
    
        }
      }
    )
  }

   youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}

  getAllVideoOflbum(id) {
    this.service.getAllVideoOfAlbum(id).subscribe(
      data => {
        this.albumVideos = data.data;
        for (var i = 0; i < this.albumVideos.length; i++) {
          
        var output =this.youtube_parser(this.albumVideos[i].albumVideoPath)
          var url='http://img.youtube.com/vi/' + output + '/default.jpg'
          this.albumVideos[i].thumb =url;
         
        }  
       setTimeout(()=>{
        galleryJs.trimPhotoHeader();
        galleryJs.trim();
        galleryJs.startVideo();
       },500)
     

       
      },
      error => {

      }
    )
  }
  noRecordFound() {
    $('#loader').css('display', 'none');
    this.recordStatus = 'No more Records found';
    this.noDataFound++;
  }
  loadMore(txt) {
    
    var self = this;
    if (txt == 'Load More') {
      if (this.filterApplied == 0) { this.searchAlbum(this.videoPage) }
      else { this.searchAlbum(this.videoPage); }
    }
    else {
      this.noRecordFound()
    }
  }
}
