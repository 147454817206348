import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { configFile } from '../../shared/config';
import { SharedService } from '../../shared/gallery.service';
declare var galleryJs 
@Component({
  selector: 'app-photogallery',
  templateUrl: './photogallery.component.html',
  styleUrls: ['./photogallery.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class PhotogalleryComponent implements OnInit {

  albumList:any
  public roolurl:string=""
  config:any

  constructor(private service:SharedService) {    
    this.config =new configFile();  
  }

  ngOnInit() {
  

    this.service.getPhotoAlbumForSlider().subscribe(data=>{
      if(data.statusCode==200){   
      
        this.albumList = data.data; 
       setTimeout(() => {
        galleryJs.LoadFlexGallery()
       }, 500);
      }else{
        this.albumList=null;
      }
    })  
  }

}
