import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared/gallery.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-notice1',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NoticeComponent implements OnInit {
  NoticeList: any[];
  NoticeLength: any;
  constructor(private service: SharedService, private route: Router) { }

  ngOnInit() {
    this.service.getAllActiveNotice().subscribe(data => {
      this.NoticeList = data.data;
      if (this.NoticeList) {
        this.NoticeLength=this.NoticeList.length
      }

    })
  }

  Navigatetodetail(id) {
    this.route.navigateByUrl('/getnoticedetail/' + id);
  }

}
