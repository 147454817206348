import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared/gallery.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-event-display-month',
  templateUrl: './event-display-month.component.html',
  styleUrls: ['./event-display-month.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class EventDisplayMonthComponent implements OnInit {
  month
  list:any[];
  constructor(private _service :SharedService,private router: ActivatedRoute) { }

  ngOnInit() {
    this.router.params.subscribe(data => {
      this.month = data["month"];
    this._service.getEventsOfMonth(this.month).subscribe((result:any)=>{
    this.list=result.data;
    })
  });
  }

}
