import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SharedService } from '../shared/gallery.service';
import { Router } from '@angular/router';
import * as $ from 'jquery';
declare var commonFile, galleryJs;
import { configFile } from '../shared/config';
//var scrollLock = false;

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MediaGalleryComponent implements OnInit {
  configFile = new configFile();
  folder:'Thumb'
  mediaIconUrl = this.configFile.mediaIconUrl; MediaGetTopTenRecods; page = 1;
  filterApplied = 0; TotalMedia; noDataFound = 0; Medias = []; errorMsg = ''; searchBy = '1'; years = []; errYearMsg = '';
  allSearch = ''; AlbumTitle = ''; year = ''; eventDate = ''; Descriptions = ''; text = ''; recordStatus = 'Load More';
  pipe = new DatePipe('en-US'); errSearchMsg = '';
  constructor(private service: SharedService, private router: Router) { }
  public arr;

  ngOnInit(): void {

    var self = this;
    this.loadMedia();
    this.loadYear();
    
  }
  loadYear() {
    this.service.getMediaYears().subscribe(data => {
      if (data.statusCode == 200 && data.errorMessage == 'OK') {
        this.years = data.data;
      }
    },
      error => {
        this.errorMsg = error
      }
    )
  }
  loadMedia() {
    if (this.noDataFound == 0) {
      $('#loader').css('display', 'none');
      this.recordStatus = 'Load More';
      this.service.getMedia('1', '', this.page).subscribe(
        data => {
          if (data.statusCode == 200 && data.errorMessage == 'OK') {
            this.arr = data.data;
            debugger
            if(this.arr.length>0){
              this.TotalMedia = this.arr[0].total;
            }else
            this.TotalMedia = this.Medias.length;

            if (this.arr.length > 0 || this.page == 1) {
              if (this.page == 1) { this.Medias = []; }

              for (let i = 0; i < this.arr.length; i++) {
                this.Medias.push(this.arr[i]);
              }

              galleryJs.trim();
              galleryJs.trimPhotoHeader();
            
              this.page++;
            }
            else {
              this.noRecordFound();
            }
            //setTimeout(function () {
            //    scrollLock = false;
            //}, 1000)
          }
          else {
            this.noRecordFound();
          }

        },
        error => {
          this.errorMsg = error
        }
      )
    }
  }
  MediaGetAllSearch(page) {

    this.page = page;
    this.recordStatus = 'Load More';
    $('#loader').css('display', 'none');

    if (this.searchBy == '1') this.text = this.allSearch
    else if (this.searchBy == '2') this.text = this.AlbumTitle
    else if (this.searchBy == '3') this.text = this.year
    else if (this.searchBy == '4') {
      var d;
      if (this.eventDate != null) {
        d = this.pipe.transform(this.eventDate, 'dd-MMM-yyyy');
      }
      else { d = '' }
      this.text = d;
    }
    else if (this.searchBy == '5') this.text = this.Descriptions;
    if (!this.text) {
      this.noDataFound = 0; this.page = 1; this.filterApplied = 0; this.Medias = []; this.loadMedia(); return false
    }
    this.filterApplied++;
    this.service.MediaGetAllSearch(this.searchBy, this.text, this.page).subscribe(
      data => {

        if (data.statusCode == 200 && data.errorMessage == 'OK') {
          this.arr = data.data;
          if (this.arr.length > 0 || this.page == 1) {
            if (this.page == 1) { this.Medias = []; }
            for (var i = 0; i < this.arr.length; i++) {
              this.Medias.push(this.arr[i]);
            }

            this.page++
            this.TotalMedia = this.Medias.length;
          }
          else {
            this.noRecordFound()
          }
        }
        else {
          this.noRecordFound()
        }
      },
      error => {
        this.errorMsg = error
      }
    )
  }


  noRecordFound() {
    $('#loader').css('display', 'none');
    this.recordStatus = 'No more Records found';
    this.noDataFound++;
  }
  loadMore(txt) {
    var self = this;
    if (txt == 'Load More') {
      if (this.filterApplied == 0) { this.loadMedia() }
      else { this.MediaGetAllSearch(self.page); }
    }
    else {
      this.noRecordFound()
    }
  }

}
