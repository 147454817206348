import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-co-scholastic-areas',
  templateUrl: './co-scholastic-areas.component.html',
  styleUrls: ['./co-scholastic-areas.component.css']
})
export class CoScholasticAreasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
