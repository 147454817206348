import { Component, OnInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-school-council',
  templateUrl: './school-council.component.html',
  styleUrls: ['./school-council.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class SchoolCouncilComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
