import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared/gallery.service';
import { configFile } from '../shared/config';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultHeaderComponent implements OnInit {
  config
  menusList:any;
  arr:any[];
   arr2:any[];
  constructor(private _service:SharedService) { 
    this.config =new configFile();   
    this._service.getWebMenusList().subscribe(data=>{
      if(data.statusCode==200){ 
        if(data.data.length!=0){    
          this.menusList=new Array<ParentMenuList>();      
       
           for(var i =0; i<data.data.length;i++)
           {
             
             var obj =new  ParentMenuList();
             if(data.data[i].parentMenuId==0){
                obj.Title=data.data[i].title
                obj.Icon=data.data[i].iconCSS
                if(data.data[i].pageId!=null)
                {
                obj.url="/page/"+data.data[i].url
                }else{
                  obj.url=data.data[i].url;
                }
                obj.target=data.data[i].target
                
                obj.PageId=data.data[i].pageId;
                this.arr= new Array<SubMenusList>();
                for(var j =0; j<data.data.length;j++)
                {
                    var obj1 =new  SubMenusList();

                  if(data.data[i].menuId==data.data[j].parentMenuId)
                  {
                    
                    obj1.Title=data.data[j].title
                    obj1.Icon=data.data[j].iconCSS
                    if(data.data[j].pageId!=null)
                    {
                    obj1.url="/page/"+data.data[j].url
                    }else{
                      obj1.url=data.data[j].url;
                    }
                    obj1.target=data.data[j].target 
                    obj1.PageId=data.data[j].pageId;
                    for(var k =0; k<data.data.length;k++)
                    {
                        this.arr2=new Array<ChildSubMenusList>();
                      if(data.data[j].menuId==data.data[k].parentMenuId)
                      {
                        var obj2 =new  ChildSubMenusList();
                        obj2.Title=data.data[k].title
                        obj2.Icon=data.data[k].iconCSS
                      if(data.data[k].pageId!=null)
                        {
                        obj2.url="/page/"+data.data[k].url
                        }else{
                          obj2.url=data.data[k].url;
                        }
                          
                        obj2.target=data.data[k].target 
                        obj2.PageId=data.data[k].pageId;
                        this.arr2.push(obj2);
                      }              
                    }
                    obj1.ChildSubMenu=this.arr2;
                    this.arr.push(obj1);
                  }              
                }
                obj.SubMenu=this.arr
                this.menusList.push(obj);
             }
           }  
        }
      }
    }) 

  }

  ngOnInit() {
    
  }

}

export class  ParentMenuList{
  
  Title:string;
  url:string;
  target:string;
  Icon:string;
  PageId:number;
  SubMenu:SubMenusList[];
}
export class  SubMenusList{
  Title:string;
  url:string;
  target:string;
  Icon:string;
  PageId:number;
  ChildSubMenu:ChildSubMenusList[];
}
export class  ChildSubMenusList{
  Title:string;
  url:string;
  PageId:number;
  target:string;
  Icon:string;
}