import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-default-footer',
  templateUrl: './default-footer.component.html',
  styleUrls: ['./default-footer.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class DefaultFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
